import Vue from 'vue'
import Vuetify, {
  Resize,
  Intersect,
  Ripple,
  VAlert,
  VApp,
  VAppBar,
  VAppBarNavIcon,
  VAutocomplete,
  VAvatar,
  VBadge,
  VBanner,
  VBottomSheet,
  VBreadcrumbs,
  VBreadcrumbsItem,
  VBtn,
  VCalendar,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCarousel,
  VCarouselItem,
  VCheckbox,
  VChip,
  VCol,
  VCombobox,
  VContainer,
  VDataTable,
  VDatePicker,
  VDialog,
  VDivider,
  VEditDialog,
  VExpandTransition,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VExpansionPanels,
  VFlex,
  VFadeTransition,
  VFooter,
  VForm,
  VHover,
  VIcon,
  VImg,
  VLabel,
  VLayout,
  VList,
  VListGroup,
  VListItem,
  VListItemAction,
  VListItemAvatar,
  VListItemContent,
  VListItemGroup,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMain,
  VMenu,
  VNavigationDrawer,
  VOverlay,
  VPagination,
  VProgressCircular,
  VProgressLinear,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSheet,
  VSimpleCheckbox,
  VSimpleTable,
  VSkeletonLoader,
  VSlideXReverseTransition,
  VSlideXTransition,
  VSlideYTransition,
  VSnackbar,
  VSpacer,
  VStepper,
  VStepperContent,
  VStepperHeader,
  VStepperStep,
  VSubheader,
  VSwitch,
  VSystemBar,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTabsSlider,
  VTextarea,
  VTextField,
  VTimePicker,
  VToolbar,
  VToolbarItems,
  VToolbarTitle,
  VTooltip
} from 'vuetify2/lib'
import icons from '~/util/icons.js'

// CSS
import '~/assets/css/snui/overrides.sass'
import '~/assets/css/snIcons.css'
import '~/assets/css/snui/index.scss'

import { svgIcons } from '@ncino/snui'
import * as colorVariables from '~/assets/css/snui/_snColorVariables.scss'
// eslint-disable-next-line
const Icons = Object.keys(svgIcons).reduce((dict, iconName) => ({ ...dict, [`${iconName}`]: { component: svgIcons[iconName] } }), {})
const snThemeColors = Object.keys(colorVariables).reduce((dict, colorName) => ({ ...dict, [colorName]: colorVariables[colorName] }), {})

VBtn.options.props.depressed.default = true

Vue.use(Vuetify, {
  components: {
    VAlert,
    VApp,
    VAppBar,
    VAppBarNavIcon,
    VAutocomplete,
    VAvatar,
    VBadge,
    VBanner,
    VBottomSheet,
    VBreadcrumbs,
    VBreadcrumbsItem,
    VBtn,
    VCalendar,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VCarousel,
    VCarouselItem,
    VCheckbox,
    VChip,
    VCol,
    VCombobox,
    VContainer,
    VDataTable,
    VDatePicker,
    VDialog,
    VDivider,
    VEditDialog,
    VExpandTransition,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VExpansionPanels,
    VFlex,
    VFadeTransition,
    VFooter,
    VForm,
    VHover,
    VIcon,
    VImg,
    VLabel,
    VLayout,
    VList,
    VListGroup,
    VListItem,
    VListItemAction,
    VListItemAvatar,
    VListItemContent,
    VListItemGroup,
    VListItemIcon,
    VListItemSubtitle,
    VListItemTitle,
    VMain,
    VMenu,
    VNavigationDrawer,
    VOverlay,
    VPagination,
    VProgressCircular,
    VProgressLinear,
    VRadio,
    VRadioGroup,
    VRow,
    VSelect,
    VSheet,
    VSimpleCheckbox,
    VSimpleTable,
    VSkeletonLoader,
    VSlideXReverseTransition,
    VSlideXTransition,
    VSlideYTransition,
    VSnackbar,
    VSpacer,
    VStepper,
    VStepperContent,
    VStepperHeader,
    VStepperStep,
    VSubheader,
    VSwitch,
    VSystemBar,
    VTab,
    VTabItem,
    VTabs,
    VTabsItems,
    VTabsSlider,
    VTextarea,
    VTextField,
    VTimePicker,
    VToolbar,
    VToolbarItems,
    VToolbarTitle,
    VTooltip
  },
  directives: {
    Resize,
    Intersect,
    Ripple
  }
})

const opts = {
  breakpoint: {
    thresholds: {
      xs: 320,
      sm: 540,
      md: 990,
      lg: 1280
    },
    // ignores scrollbar to match breakpoints with css @media
    scrollBarWidth: -1
  },
  icons: {
    values: {
      ...icons,
      ...Icons
    }
  },
  theme: {
    themes: {
      light: {
        primary: '#FFFFFF',
        ...snThemeColors
      },
      dark: {
        tertiary: '#1F1F1F',
        ...snThemeColors
      }
    }
  }
}

export default new Vuetify(opts)
